/* @tailwind base;
@tailwind components; */
@tailwind utilities;

*::-webkit-scrollbar {
  display: none;
}

pre {
  font-family: inherit !important;
}

.ace_mobile-menu {
  display: none !important;
}

body {
  user-select: none;
}

.prevent-selection {
  user-select: none;
}